import React from 'react';

import { PageProps, graphql, useStaticQuery } from 'gatsby';

// Component imports
import { Section, Text, Button } from '@utils';
import { StaticImage } from 'gatsby-plugin-image';
import Work from '@component/Home/Work';
import SEO from '@component/SEO';
import Post from '@component/Blog/Post';
import CTA from '@component/CTA';
import Reveal from '@global/Reveal';

const Index: React.FC<PageProps> = () => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      posts: allSanityPosts(
        sort: { fields: _createdAt, order: DESC }
        limit: 4
      ) {
        edges {
          node {
            _createdAt
            _id
            title
            slug {
              current
            }
            image {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            author {
              name
              avatar {
                asset {
                  gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      works: allSanityWork(
        filter: { isFeatured: { eq: true } }
        sort: { order: DESC, fields: _createdAt }
      ) {
        edges {
          node {
            _key
            title
            slug {
              current
            }
            client {
              name
              logo {
                asset {
                  gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
                }
              }
            }
            description
            thumbnail {
              asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title="Home"
        path="/"
        description="Coral Dev is the premier Minecraft plugins development team. We create custom plugins for your Minecraft server and sell premade plugins on various market places. Contact us today at hello@coraldev.net for a free estimate."
      />
      <div className="bg-opacity-10 relative h-screen overflow-hidden">
        <Section id="hero" className="relative z-10 h-full">
          <div className="md:items-start sm:justify-start md:justify-center flex flex-col items-center justify-end h-full">
            <div className="md:w-2/3 lg:space-y-16 flex flex-col justify-center h-full space-y-8">
              <Reveal>
                <div className="space-y-5">
                  <StaticImage
                    src="../assets/img/logo.png"
                    alt="Coral Dev"
                    className="lg:w-auto w-48"
                    width={305}
                    height={92}
                    placeholder="tracedSVG"
                    quality={99}
                  ></StaticImage>
                  <Text style="h1" tag="h1">
                    The premier Minecraft plugins development team.
                  </Text>
                </div>
                <div>
                  <Button type="internal" variant="primary" href="/#contact">
                    Contact Us
                  </Button>
                </div>
              </Reveal>
            </div>
          </div>
        </Section>
        <div className="md:absolute md:inset-0 md:flex md:flex-col md:items-end md:justify-end lg:items-center hidden">
          <StaticImage
            src="../assets/img/coral.png"
            alt=""
            placeholder="blurred"
            className="xl:translate-x-3/4 transform translate-x-1/2"
            objectPosition="left"
            quality={50}
          />
        </div>
      </div>
      <div className="bg-gray-700">
        <Section id="about">
          <div className="lg:flex lg:items-center lg:space-x-10 lg:space-y-0 space-y-12">
            <div className="lg:w-1/2 space-y-8">
              <Reveal>
                <div>
                  <Text tag="h2" style="h2">
                    <span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
                      Who's Coral Dev?
                    </span>
                  </Text>
                </div>
                <Text>
                  Coral Dev is a Minecraft development team offering custom
                  plugin development for your server and premade plugins
                  available for purchase. We employ top developers in the
                  industry to work with clients and produce premium, premade
                  plugins. We use our own proprietary codebase as well to
                  streamline development to get your custom plugin done on time!
                </Text>
                <Text>
                  Our team has a combined experience of over 5 years and our
                  goal is to provide top-notch, professional services. We make
                  plugin development as easy as one-two-three and streamline the
                  coding process to make custom plugin development a breeze.
                </Text>
                <Button type="internal" variant="primary" href="/team">
                  View Our Team
                </Button>
              </Reveal>
            </div>
            <div className="lg:w-1/2">
              <Reveal>
                <StaticImage
                  src="../assets/img/glyph.png"
                  alt="Coral Dev"
                  placeholder="tracedSVG"
                  quality={95}
                  layout="fixed"
                  className="mx-auto"
                />
              </Reveal>
            </div>
          </div>
        </Section>
      </div>

      {data.works.edges.length > 0 && (
        <Section id="our-works">
          <div className="md:space-y-12 lg:space-y-20 space-y-8">
            <div className="space-y-4 text-center">
              <Text style="h2" tag="h2">
                <span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
                  Featured Work
                </span>
              </Text>
              <Text className="max-w-2xl mx-auto">
                Checkout Coral Dev's latest projects!{' '}
              </Text>
            </div>
            <div className="md:grid-cols-2 grid gap-8">
              {data.works.edges.map(({ node }: any) => (
                <Work
                  slug={node.slug.current}
                  key={node._id}
                  name={node.title}
                  backgroundImage={node.thumbnail.asset.gatsbyImageData}
                  client={node.client.logo.asset.gatsbyImageData}
                  clientName={node.client.name}
                ></Work>
              ))}
            </div>
            <div className="flex justify-center">
              <Button type="internal" variant="primary" href="/work">
                View Our Work
              </Button>
            </div>
          </div>
        </Section>
      )}
      {data.posts.edges.length > 0 && (
        <Section id="posts">
          <div className="md:space-y-12 lg:space-y-20 space-y-8">
            <div className="space-y-4 text-center">
              <Text style="h2" tag="h2">
                <span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
                  Recent Posts
                </span>
              </Text>
              <Text className="max-w-2xl mx-auto">
                Checkout the latest posts from our blog, which offers tips and
                guides on running a modern Minecraft server.
              </Text>
            </div>
            <div className="md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid gap-8">
              {data.posts.edges.map(({ node }: any, i: number) => (
                <Reveal delay={0.25 * i}>
                  <Post
                    key={node._id}
                    title={node.title}
                    author={node.author.name}
                    publishDate={node._createdAt}
                    authorImage={node.author.avatar.asset.gatsbyImageData}
                    image={node.image.asset.gatsbyImageData}
                    slug={node.slug.current}
                  />
                </Reveal>
              ))}
            </div>
            <div className="flex justify-center">
              <Button type="internal" variant="primary" href="/work">
                View Our Blog
              </Button>
            </div>
          </div>
        </Section>
      )}
      <CTA />
    </>
  );
};

export default Index;
