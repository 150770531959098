import React from 'react';

import { m as motion } from 'framer-motion';

// Component imports
import { Text } from '@utils';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
interface Props {
  name: string;
  slug: string;
  client: any;
  backgroundImage: any;
  clientName: string;
}

const Work: React.FC<Props> = ({
  name,
  slug,
  client,
  backgroundImage,
  clientName,
}) => {
  return (
    <article>
      <Link to={`/work/${slug}`} className="block">
        <motion.div
          initial="hidden"
          whileHover="hover"
          whileFocus="hover"
          className="rounded-2xl lg:h-80 2xl:h-96 relative w-full h-56 overflow-hidden bg-gray-900"
        >
          <div className="absolute inset-0">
            <GatsbyImage
              image={backgroundImage}
              alt=""
              className="rounded-2xl w-full h-full"
            />
            <div className="opacity-80 rounded-2xl bg-gradient-to-tr from-gray-700 to-coral lg:opacity-50 absolute inset-0"></div>
          </div>
          {/* Mobile only */}
          <div className="lg:hidden rounded-2xl absolute inset-0 flex flex-col items-center justify-center p-6 space-y-3">
            <Text style="h3" tag="h3" className="text-center">
              {name}
            </Text>
            <div>
              <GatsbyImage
                image={client}
                alt={clientName}
                className="w-24"
              ></GatsbyImage>
            </div>
          </div>

          <motion.div
            transition={{
              type: 'spring',
              duration: 0.5,
              stiffness: 50,
              mass: 0.5,
            }}
            variants={{
              hover: { y: '0%' },
              hidden: { y: '100%' },
            }}
            className="bg-gradient-to-tr from-reef to-coral rounded-2xl absolute inset-0 flex flex-col items-center justify-center p-6 space-y-3"
          >
            <Text style="h3" tag="h3" className="text-center">
              {name}
            </Text>
            <div>
              <GatsbyImage
                image={client}
                alt={clientName}
                className="w-24"
              ></GatsbyImage>
            </div>
          </motion.div>
        </motion.div>
      </Link>
    </article>
  );
};

export default Work;
